import GATSBY_COMPILED_MDX from "/opt/build/repo/src/content/cycle-de-la-tour-de-garde.mdx";
import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/layout";
import {Heading, Box, Text, Container, Flex, Spacer} from '@chakra-ui/react';
import {MDXProvider} from "@mdx-js/react";
import {GatsbyImage, getImage, getSrc} from "gatsby-plugin-image";
import {SEO} from "../components/seo";
import {useSiteMetadata} from "../hooks/use-site-metadata";
import {ChevronLeftIcon, ChevronRightIcon} from '@chakra-ui/icons';
const BlogPost = ({children, pageContext}) => {
  const node = pageContext.node;
  const image = getImage(node.frontmatter.featuredImage);
  const date = new Date(node.frontmatter.date).toLocaleDateString("fr-FR");
  console.log(pageContext);
  return React.createElement(Layout, null, React.createElement(Box, {
    width: {
      base: "xs",
      sm: "sm",
      md: "xl",
      lg: "4xl"
    }
  }, React.createElement(Heading, {
    as: "h3",
    textAlign: "center",
    paddingBottom: "20"
  }, node.frontmatter.title), React.createElement(Text, {
    textStyle: "italic",
    fontSize: "xs",
    paddingBottom: "1"
  }, "Date : ", date), React.createElement(Text, {
    textStyle: "italic",
    fontSize: "xs",
    paddingBottom: "10"
  }, "Temps de lecture : ", node.frontmatter.readTime, " minutes"), React.createElement(MDXProvider, {
    components: {
      h1: Heading,
      h2: props => React.createElement(Heading, Object.assign({
        as: "h2",
        fontSize: "2xl"
      }, props)),
      p: Text,
      blockquote: props => React.createElement(Text, Object.assign({
        fontStyle: "italic",
        fontSize: "s",
        padding: "5"
      }, props))
    }
  }, children), React.createElement(Text, {
    fontWeight: "bold",
    textStyle: "italic",
    py: "2"
  }, node.frontmatter.author), node.frontmatter.quote ? React.createElement(Box, {
    my: "12"
  }, React.createElement(Container, {
    centerContent: "true"
  }, React.createElement(Text, {
    paddingTop: "2",
    fontSize: "1.25em",
    fontweight: "light",
    textAlign: "center",
    fontStyle: "italic"
  }, "\"", node.frontmatter.quote, "\""))) : React.createElement(React.Fragment), React.createElement(Box, {
    my: "10"
  }, React.createElement(Container, {
    centerContent: "true"
  }, React.createElement(GatsbyImage, {
    image: image,
    alt: node.frontmatter.featuredImageAlt
  }), React.createElement(Text, {
    paddingTop: "2",
    fontSize: "0.75em",
    textAlign: "center",
    fontStyle: "italic"
  }, node.frontmatter.featuredImageSource, " - ", React.createElement("a", {
    href: node.frontmatter.featuredImageSourceLink,
    referrerpolicy: "origin",
    target: "_blank"
  }, node.frontmatter.featuredImageSourceLink)))), React.createElement(Flex, null, pageContext?.prev?.title ? React.createElement(Box, {
    my: "10"
  }, React.createElement(Link, {
    to: pageContext?.prev?.slug
  }, React.createElement(Container, {
    maxW: "3xs",
    centerContent: "true"
  }, React.createElement(ChevronLeftIcon), React.createElement(Text, {
    paddingTop: "2",
    fontSize: "0.75em",
    textAlign: "center"
  }, pageContext.prev.title)))) : React.createElement(React.Fragment), React.createElement(Spacer), pageContext?.next?.title ? React.createElement(Box, {
    my: "10"
  }, React.createElement(Link, {
    to: pageContext?.next?.slug
  }, React.createElement(Container, {
    maxW: "3xs",
    centerContent: "true"
  }, React.createElement(ChevronRightIcon), React.createElement(Text, {
    paddingTop: "2",
    fontSize: "0.75em",
    textAlign: "center"
  }, pageContext.next.title)))) : React.createElement(React.Fragment))));
};
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const Head = ({pageContext}) => {
  const node = pageContext.node;
  const {title: defaultTitle, description: defaultDescription, image, siteUrl, twitterUsername, favicon} = useSiteMetadata();
  const articleBody = node.body.replaceAll('"', '\\"');
  const articleImage = getSrc(node.frontmatter.featuredImage);
  const cardImage = node.frontmatter.cardImage ? getSrc(node.frontmatter.cardImage) : getSrc(node.frontmatter.featuredImage);
  return React.createElement(SEO, {
    title: `${node.frontmatter.title} | La Pensine`
  }, React.createElement("meta", {
    property: "og:type",
    content: "article"
  }), React.createElement("meta", {
    property: "og:title",
    content: node.frontmatter.title
  }), React.createElement("meta", {
    property: "og:description",
    content: node.frontmatter.excerpt
  }), React.createElement("meta", {
    property: "og:url",
    content: `https://la-pensine.fr${node.frontmatter.slug}`
  }), React.createElement("meta", {
    property: "og:image",
    content: `https://la-pensine.fr${cardImage}`
  }), node.frontmatter.tags.split(",").map(tag => React.createElement("meta", {
    property: "article:tag",
    content: tag
  })), React.createElement("meta", {
    name: "twitter:card",
    content: "summary_large_image"
  }), React.createElement("meta", {
    name: "twitter:title",
    content: node.frontmatter.title
  }), React.createElement("meta", {
    name: "twitter:description",
    content: node.frontmatter.excerpt
  }), React.createElement("meta", {
    name: "twitter:creator",
    content: "@LaPensineBlog"
  }), React.createElement("meta", {
    name: "twitter:site",
    content: "@LaPensineBlog"
  }), React.createElement("meta", {
    name: "twitter:url",
    content: `https://la-pensine.fr${node.frontmatter.slug}`
  }), React.createElement("meta", {
    name: "twitter:image",
    content: `https://la-pensine.fr${cardImage}`
  }), React.createElement("meta", {
    name: "twitter:image:alt",
    content: node.frontmatter.featuredImageAlt
  }), React.createElement("script", {
    type: "application/ld+json"
  }, `{ "@context": "https://schema.org", 
            "@type": "BlogPosting",
            "headline": "${node.frontmatter.title}",
            "editor": "${node.frontmatter.author}", 
            "genre": "Revue de livre", 
            "wordcount": "${node.frontmatter.wordCount}",
            "publisher": "La Pensine",
            "url": "${node.frontmatter.title}",
            "dateCreated": "${node.frontmatter.date}",
            "dateModified": "${node.frontmatter.date}",
            "datePublished": "${node.frontmatter.date}",
            "description": "${node.frontmatter.excerpt}",
            "articleBody": "${articleBody}",
              "author": {
                "@type": "Person",
                "name": "${node.frontmatter.author}"
              },
            "about": {
              "@type": "Book",
              "inLanguage": "French",
              "isbn": "${node.frontmatter.isbn}",
              "name": "${node.frontmatter.bookTitle}",
              "author": {
                "@type": "Person",
                "name": "${node.frontmatter.bookAuthor}"
              }
            }
        },
        {
          "@context": "http://schema.org",
          "@type": "Blog",
          "name": "${defaultTitle}",
          "url": "${siteUrl}",
          "description": "${defaultDescription}",
          "publisher": {
            "@type": "Person",
            "name": "AS"
          }
        }`));
};
